<template>
  <ul class="sector-solutions-block">
    <template v-for="sector in sectorsToDisplay" :key="sector.id">
      <li
        v-if="
          props.selectedSector == null ||
          typeof props.selectedSector == 'undefined' ||
          props.selectedSector != sector.id
        "
        :class="`sector-solutions-item sector-solutions-${sector.id}`"
      >
        <div class="sector-solutions-item-inner">
          <div class="icon-wrapper">
            <v-icon :icon="`$${sector.iconAlt}`"></v-icon>
          </div>
          <nuxt-link
            :to="
              sector.id === 'all'
                ? localePath({ name: 'solutions_overview-sector' })
                : localePath({
                    name: 'sector-PageURL',
                    params: { PageURL: sector.slug },
                  })
            "
            :external="sector.id === 'all' ? false : true"
            >{{ t(`explorer.sectors.${sector.id}.solutionsTitle`)
            }}<v-icon icon="$chevronRight"></v-icon
          ></nuxt-link>
        </div>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
const { t } = useI18n();
const localePath = useLocalePath();
const props = defineProps({
  selectedSector: {
    type: String,
    default: null,
  },
  showAllLink: {
    type: Boolean,
    default: false,
  },
});

const { sectors }: any = usePathwaysConfig();
const sectorsToDisplay = props.showAllLink
  ? {
      ...sectors,
      all: {
        id: "all",
        name: t("explorer.sectors.all.name"),
        slug: t("explorer.sectors.all.slug"),
        icon: "seedling",
        iconAlt: "seedling",
      },
    }
  : sectors;

</script>
<style lang="css" scoped></style>
